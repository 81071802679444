.errorPage {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: justify;
    justify-content: space-between;
    height: 100vh;
    width: 100%;
    background: url(/img/jpg/404.jpg) 50% / cover no-repeat;
    padding-top: 20px;
    p {
        font-size: 16px;
        text-align: center;
        text-transform: auto;
        color: #f9faf9;
        font-weight: 400;
        @media screen and (max-width: 991px) {
            font-size: 14px;
        }
    }
    a{
        color: #f9faf9;
        font-weight: 400;
        margin-bottom: auto;
        font-size: 24px;
        text-transform: uppercase;
        padding: 20px 40px;
        border: 1px solid red;
        border-radius: 10px;
        text-decoration: none;
        @media screen and (max-width: 991px) {
            padding: 5px 15px;
        }
    }
    form {
        margin-bottom: 30px;
        min-width: 430px;
        @media screen and (max-width: 991px) {
            min-width: 320px;
        }
        &::-webkit-input-placeholder {
            font-family: MontserratRegular, sans-serif;
            font-size: 18px;
            @media screen and (max-width: 991px) {
                font-size: 13px;
            }
        }
        &:-moz-placeholder{
            font-family: MontserratRegular, sans-serif;
            font-size: 18px;
            @media screen and (max-width: 991px) {
                font-size: 13px;
            }
        }
        &::-moz-placeholder{
            font-family: MontserratRegular, sans-serif;
            font-size: 18px;
            @media screen and (max-width: 991px) {
                font-size: 13px;
            }
        }
        &:-ms-input-placeholder {
            font-family: MontserratRegular, sans-serif;
            font-size: 18px;
            @media screen and (max-width: 991px) {
                font-size: 13px;
            }
        }
        label {
            width: 100%;
            color: #f9faf9;
            border-bottom: 1px solid red;
            display: -ms-flexbox;
            display: flex;
            -ms-flex-align: center;
            align-items: center;
            font-size: 18px;
        }
        input {
            width: 100%;
            background: 0 0;
            border: none;
            outline: 0;
            padding: 5px 20px;
            color: #f9faf9;
            font-size: 18px;
            font-family: MontserratRegular, sans-serif;
            @media screen and (max-width: 991px) {
                font-size: 14px;
            }
        }
    }
}